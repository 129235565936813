import React, {Component} from 'react';
import './Folder.scss';

class Folder extends Component {
  render() {
    // 175.505 x 147.053
    // 88 x 75
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="91px"
        height="76.3px"
        viewBox="0 0 175.505 147.053">
        <path className="folder-logo" id="folder" fill="#2975eb" d="M20.18,147.05a21.134,21.134,0,0,1-9.088-1.857,17.609,17.609,0,0,1-9.778-10.85A23.351,23.351,0,0,1,0,126.183q.067-5.674.11-11.35C.13,92.632.2,78.717.233,54.144q0-9.859.018-19.718c0-3.715,0-7.655,0-11.847H.293q.01-2.13.023-4.262C.382,7.239,6.882.558,17.577.284,24.111.114,30.649.125,37.188.136,43.5.145,49.807.156,56.114.006,62.969-.156,67.8,3.1,71.975,8.107c2.117,2.541,4.211,5.108,6.409,7.573A18.683,18.683,0,0,0,93.234,22.3c8.414-.022,16.832.1,25.25.228q3.338.05,6.676.1l29.539.01.42-.009a22.354,22.354,0,0,1,6.432.758,16.907,16.907,0,0,1,10.585,7.733,22.209,22.209,0,0,1,3.322,11.746c.027.758.007,1.2.007,1.2s-.071,43.169-.078,76.936q.052,2.479.112,4.958a21.221,21.221,0,0,1-1.941,9.535,18.756,18.756,0,0,1-17.711,11.548c-.345.008-.69.008-1.039,0-10.712-.286-21.431-.339-32.15-.316-11.439.029-22.885.139-34.323.14h-.849c-6.6,0-13.192-.04-19.787-.082-7.382-.045-14.767-.09-22.147-.078-8.013.018-16.026.108-24.032.343q-.45.013-.892.013C20.477,147.053,20.328,147.052,20.18,147.05Z" />
      </svg>
    );
  }
}

export default Folder;
