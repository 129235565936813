import React, { Component } from 'react';
import './DeleteContactBtn.scss';

class DeleteContactBtn extends Component {
  render() {
    return (
      <div className="delete-btn">
        <svg width="32.646" height="36">
          <g>
            <g transform="translate(4.985 0)">
              <path id="top-contact-line" d="M95.017,15.971a7.727,7.727,0,0,0,5.646-2.34A7.728,7.728,0,0,0,103,7.985a7.728,7.728,0,0,0-2.34-5.646,7.984,7.984,0,0,0-11.292,0,7.727,7.727,0,0,0-2.34,5.646,7.727,7.727,0,0,0,2.34,5.646A7.73,7.73,0,0,0,95.017,15.971ZM90.745,3.713a6.041,6.041,0,0,1,8.545,0,5.773,5.773,0,0,1,1.77,4.272,5.773,5.773,0,0,1-1.77,4.272,6.04,6.04,0,0,1-8.545,0,5.772,5.772,0,0,1-1.771-4.272,5.773,5.773,0,0,1,1.771-4.272Zm0,0"
                    transform="translate(-81.396 0)" fill="#fff" />
              <path id="bottom-contact-line" d="M27.594,256.8a19.726,19.726,0,0,0-.269-2.1,16.507,16.507,0,0,0-.515-2.106,10.405,10.405,0,0,0-.866-1.964,7.4,7.4,0,0,0-1.305-1.7,5.757,5.757,0,0,0-1.876-1.179,6.482,6.482,0,0,0-2.394-.433,2.43,2.43,0,0,0-1.3.55c-.389.254-.844.547-1.352.872a7.747,7.747,0,0,1-1.749.771,6.793,6.793,0,0,1-4.279,0,7.725,7.725,0,0,1-1.748-.77c-.5-.322-.958-.615-1.353-.872a2.428,2.428,0,0,0-1.3-.55,6.474,6.474,0,0,0-2.394.434,5.752,5.752,0,0,0-1.876,1.179,7.407,7.407,0,0,0-1.305,1.7,10.425,10.425,0,0,0-.866,1.965A16.547,16.547,0,0,0,.335,254.7a19.589,19.589,0,0,0-.269,2.1C.022,257.43,0,258.089,0,258.754a5.505,5.505,0,0,0,1.635,4.165,5.885,5.885,0,0,0,4.213,1.537H21.813a5.886,5.886,0,0,0,4.213-1.537,5.5,5.5,0,0,0,1.635-4.165c0-.668-.023-1.327-.067-1.958Zm-2.908,4.716a3.963,3.963,0,0,1-2.874,1H5.848a3.963,3.963,0,0,1-2.874-1,3.6,3.6,0,0,1-1.032-2.758c0-.621.02-1.235.061-1.824a17.67,17.67,0,0,1,.243-1.887,14.592,14.592,0,0,1,.453-1.857,8.486,8.486,0,0,1,.7-1.6,5.49,5.49,0,0,1,.959-1.257,3.819,3.819,0,0,1,1.248-.776,4.471,4.471,0,0,1,1.53-.3c.068.036.189.105.386.233.4.26.86.557,1.369.882a9.626,9.626,0,0,0,2.2.981,8.735,8.735,0,0,0,5.475,0,9.638,9.638,0,0,0,2.2-.982c.521-.333.968-.621,1.368-.882.2-.128.317-.2.385-.233a4.474,4.474,0,0,1,1.531.3,3.824,3.824,0,0,1,1.248.776,5.475,5.475,0,0,1,.959,1.258,8.458,8.458,0,0,1,.7,1.6,14.558,14.558,0,0,1,.453,1.857,17.817,17.817,0,0,1,.243,1.887h0c.041.587.062,1.2.062,1.823a3.6,3.6,0,0,1-1.032,2.757Zm0,0"
                    transform="translate(0 -231.301)" fill="#fff"/>
              <g className="contact-fill">
                <path d="M95,15.944a7.714,7.714,0,0,0,5.637-2.336,7.714,7.714,0,0,0,2.335-5.637,7.715,7.715,0,0,0-2.336-5.637,7.97,7.97,0,0,0-11.273,0,7.714,7.714,0,0,0-2.336,5.636,7.714,7.714,0,0,0,2.336,5.637A7.716,7.716,0,0,0,95,15.944Zm0,0"
                      transform="translate(-81.405)" fill="#fff"/>
                <path d="M27.548,256.78a19.694,19.694,0,0,0-.268-2.091,16.477,16.477,0,0,0-.514-2.1,10.386,10.386,0,0,0-.864-1.961,7.4,7.4,0,0,0-1.3-1.7,5.747,5.747,0,0,0-1.873-1.177,6.471,6.471,0,0,0-2.39-.433,2.427,2.427,0,0,0-1.3.55c-.388.253-.843.546-1.35.87a7.735,7.735,0,0,1-1.746.769,6.782,6.782,0,0,1-4.272,0,7.716,7.716,0,0,1-1.745-.769c-.5-.321-.957-.614-1.351-.871a2.423,2.423,0,0,0-1.3-.549,6.463,6.463,0,0,0-2.39.433,5.743,5.743,0,0,0-1.873,1.177,7.4,7.4,0,0,0-1.3,1.7,10.405,10.405,0,0,0-.864,1.961,16.519,16.519,0,0,0-.514,2.1,19.623,19.623,0,0,0-.268,2.092c-.044.633-.066,1.291-.066,1.955a5.5,5.5,0,0,0,1.632,4.158,5.876,5.876,0,0,0,4.206,1.534H21.776a5.875,5.875,0,0,0,4.206-1.534,5.494,5.494,0,0,0,1.633-4.158c0-.667-.023-1.325-.067-1.955Zm0,0"
                      transform="translate(0 -231.328)" fill="#fff"/>
              </g>
            </g>
            <circle cx="6" cy="6" r="6" transform="translate(0 24)" fill="#fff"/>
            <line x2="6.03" transform="translate(2.935 30.294)" fill="none" stroke="#d95868" strokeLinecap="round" strokeWidth="3"/>
          </g>
        </svg>
        <span>Delete Contact</span>
      </div>
    );
  }
}
export default DeleteContactBtn;
