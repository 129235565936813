import React, { Component, Fragment } from 'react';

interface ContactSharedProps {
  numberOfShares: number;
}

class ContactShared extends Component<ContactSharedProps> {
  render() {
    const { numberOfShares } = { ...this.props };
    return (
      <Fragment>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="74.044"
          height="93"
          viewBox="0 0 74.044 93"
        >
          <g
            id="Group_2773"
            data-name="Group 2773"
          >
            <path
              id="Union_71"
              data-name="Union 71"
              d="M15.656,88.914A15.739,15.739,0,0,1,4.377,84.793C1.472,82.021,0,78.264,0,73.623c0-1.786.059-3.552.177-5.251A52.652,52.652,0,0,1,.9,62.752,44.407,44.407,0,0,1,2.275,57.1a28,28,0,0,1,2.317-5.269,19.888,19.888,0,0,1,3.494-4.563,15.4,15.4,0,0,1,5.022-3.161,17.309,17.309,0,0,1,6.408-1.163c.906,0,1.783.373,3.473,1.476,1.057.689,2.276,1.477,3.622,2.339a20.691,20.691,0,0,0,4.68,2.066,18.158,18.158,0,0,0,11.455,0,20.734,20.734,0,0,0,4.683-2.067c1.36-.87,2.578-1.657,3.619-2.337,1.692-1.1,2.569-1.476,3.474-1.476a17.321,17.321,0,0,1,6.41,1.162,15.4,15.4,0,0,1,5.021,3.16,19.875,19.875,0,0,1,3.494,4.564A27.818,27.818,0,0,1,71.766,57.1a44.265,44.265,0,0,1,1.38,5.648,53.089,53.089,0,0,1,.719,5.619c.119,1.693.179,3.461.179,5.252,0,4.643-1.472,8.4-4.378,11.171a15.738,15.738,0,0,1-11.278,4.121Zm5.693-52.358a20.741,20.741,0,0,1-6.263-15.142A20.744,20.744,0,0,1,21.349,6.273,20.664,20.664,0,0,1,36.463,0,20.669,20.669,0,0,1,51.576,6.273a20.745,20.745,0,0,1,6.262,15.142,20.742,20.742,0,0,1-6.262,15.142A20.666,20.666,0,0,1,36.463,42.83,20.669,20.669,0,0,1,21.349,36.556Z"
              fill="#fff"
            />
          </g>
          <text
            id="_3-2"
            x="50%"
            y="78%"
            dominantBaseline="middle"
            textAnchor="middle"
            data-name="3"
            fill="#8e3c3c"
            fontSize="39"
            fontFamily="Montserrat-Medium, Montserrat"
            fontWeight="500"
          >
            <tspan>
              {numberOfShares}
            </tspan>
          </text>
        </svg>
      </Fragment>
    );
  }
}

export default ContactShared;
